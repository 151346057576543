import React from 'react'

import Head from '../head'
import { Years, Title } from '@cms/ui'

const Page = ({ data }) => {
  return (
    <>
      <Head />
      <div className="row">
        <div className="column">
          <Title>{"Chan KwanLok's Works"}</Title>
          <Years data={data} />
        </div>
      </div>
    </>
  )
}

export default Page

export async function getStaticProps() {
  const _data = await fetch(
    `${process.env.NEXT_SERVER_STRAPI_ENDPOINT}/api/years?populate=*`
  ).then((res) => res.json())

  const { parseData } = await import('../data')

  return {
    props: {
      data: parseData(_data, 'years').map(({ year, thumbnail, id, link }) => ({
        year,
        thumbnail,
        id,
        link,
      })),
    },
  }
}
