import NextHead from 'next/head'
// import React, { useMemo } from 'react'
import React from 'react'
import { loader as imageLoader } from '@cms/image'

const defaultThumbnail =
  'https://wp.chankwanlok.com/wp-content/uploads/%E6%B7%B1%E5%B1%B1%E5%A4%A7%E6%BE%A42-2020-1080w.jpg'

const Head = ({
  title,
  desc = "CHAN KWAN LOK's Portfolio",
  ogTitle = 'Way Down Deep II | 深山大澤II',
  img = defaultThumbnail,
  imgAlt = ogTitle,
}) => {
  const thumbnail = imageLoader({ w: 640, src: img })

  return (
    <NextHead>
      <title>
        {title ? `${title} | CHAN KWAN LOK | 陳鈞樂` : 'CHAN KWAN LOK | 陳鈞樂'}
      </title>
      <meta name="description" content={desc} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:alt" contentname="twitter:card" content="summary" />
      <meta name="twitter:image:alt" content={imgAlt} />
    </NextHead>
  )
}

export default Head
